import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  post(obj,url) {
    var mapForm = document.createElement("form");
    mapForm.method = "POST";
    mapForm.action = url;
    Object.keys(obj).forEach(function(param){
      var mapInput = document.createElement("input");
      mapInput.type = "hidden";
      mapInput.name = param;
      mapInput.setAttribute("value", obj[param]);
      mapForm.appendChild(mapInput);
  });
  document.body.appendChild(mapForm);
  mapForm.submit();
}


}
