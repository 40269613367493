import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';


import { JwtInterceptor } from './_shared/helpers/jwt.interceptor';
import { ErrorInterceptor } from './_shared/helpers/error.interceptor';
import { ToastrModule } from 'ngx-toastr';

/**Components */
import { AppComponent } from './app.component';

/**Services */
import { RedirectService } from '../app/_shared/services/redirect.service'
import { LoaderInterceptorService } from '../app/_shared/services/loader-interceptor.service'
import { AuthService } from '../app/_shared/services/auth.service'
import { SpectacleService } from '../app/_shared/services/spectacle.service'
import { CartService } from '../app/_shared/services/cart.service'
import { CommandService } from '../app/_shared/services/command.service'
import { AuthGuard } from './_shared/_guards/auth.guard';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule} from '@angular/material';


import { LOCALE_ID } from '@angular/core';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');


import { LoadersModule } from '@shared/loaders/loaders.module';
import { SharedModule } from '@shared/shared.module';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';

export function setupApp(spectacleService: SpectacleService, cartService: CartService) {
   return () => {
     spectacleService.getMinPrices().subscribe((obj:any) => {
       if ( obj && obj.min_prices) {
         spectacleService.setMinPrices(obj.min_prices)
       }
       if ( obj && obj.is_command_confirmed) {
         localStorage.removeItem('livraisonData')
         localStorage.removeItem('livraisonType')
         localStorage.removeItem('last_command_id')
         cartService.clearCart()
       }
     })
   };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    LoadersModule,
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    // AngularFontAwesomeModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    MatNativeDateModule,
    DatePipe,
    RedirectService,
    CommandService,
    CartService,
    SpectacleService,
    AuthService,
    AuthGuard,
    { provide: APP_INITIALIZER  ,deps: [ SpectacleService, CartService ], useFactory: setupApp, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'fr' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
