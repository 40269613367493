import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import * as _ from 'lodash';
import * as jwt_decode from 'jwt-decode';
import { filter } from 'minimatch';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public checksToUse = [] ;
  myCart$: Observable<string>;
  cartSubject: Subject<any> = new Subject<any>();
  myCartStatus$: Observable<boolean>;
  cartStatusSubject: Subject<boolean> = new Subject<boolean>();
  constructor(toastr: ToastrService, private http: HttpClient) {
    this.myCart$ = this.cartSubject.asObservable();
    this.myCartStatus$ = this.cartStatusSubject.asObservable();
  }

  initCart() {
    let cardParse = JSON.parse(localStorage.getItem('cart')) || []
    this.cartSubject.next(cardParse);
  }

  opencloseCart(open) {
    this.cartStatusSubject.next(open);
  }


  addToCard(item) {

    let cardParse = JSON.parse(localStorage.getItem('cart')) || []
    let elementToAdd = {}
    elementToAdd['spectacle'] = this.getDataFromSpectacle(item.spectacle)
    elementToAdd['restaurant'] = this.getDataFromRestaurant(item.restaurant)
    elementToAdd['spectacle']['placesListContent'] = this.getDataFromPlacesListContent(item.placesListContent)
    cardParse.push(elementToAdd)
    localStorage.setItem('cart', JSON.stringify(cardParse));
    this.cartSubject.next(cardParse);
    return cardParse.length - 1
  }



  updateCartItem(index, spectacle, placesListContent, restaurant) {
    let cardParse = JSON.parse(localStorage.getItem('cart')) || []

    if (cardParse[index]) {
      cardParse[index].restaurant = this.getDataFromRestaurant(restaurant)
      cardParse[index].spectacle = this.getDataFromSpectacle(spectacle)
      cardParse[index].spectacle.placesListContent = this.getDataFromPlacesListContent(placesListContent)
      localStorage.setItem('cart', JSON.stringify(cardParse));
      this.cartSubject.next(cardParse);
    }
  }

  addGiftToCard(type) {
    let cardParse = JSON.parse(localStorage.getItem('cart')) || []
    let index = _.findIndex(cardParse, item => item.type && item.id == type.id)
    if (index == -1) {
      type.qte = 1
      type.totalPrice = type.amount * 1
      cardParse.push(type)
    } else {
      cardParse[index].qte += 1
      cardParse[index].totalPrice = cardParse[index].qte * cardParse[index].amount * 1
    }
    localStorage.setItem('cart', JSON.stringify(cardParse))
    this.cartSubject.next(cardParse);

  }
  PersonalizeGiftCards(message, thematic) {
    let cardParse = JSON.parse(localStorage.getItem('cart')) || []
    let index = _.findIndex(cardParse, item => item.type && item.amount)
    let gifts = _.filter(cardParse, item => item.type && item.amount)
    if (index != -1) {
      _.filter(cardParse, function(item) {
        if (item.type && item.amount) {
          item["message"] = message
          item["thematic"] = thematic
        }
      })
      localStorage.setItem('cart', JSON.stringify(cardParse));
    }
    this.cartSubject.next(cardParse)
  }

  getPersonalizeGiftCards() {
    return localStorage.getItem('PersonalizedGiftData')
  }

  commandConfirmation(cardParse) {
    localStorage.setItem('cart', JSON.stringify(cardParse));
    this.cartSubject.next(cardParse);

  }

  getFormToken(options) {
    // let headers = new HttpHeaders()
    // headers.append('Authorization', 'Basic Njk4NzYzNTc6dGVzdHBhc3N3b3JkX0RFTU9QUklWQVRFS0VZMjNHNDQ3NXpYWlEyVUE1eDdN')
    // headers.append('Content-Type', 'application/json')
    //
    //
    // return this.http.post('https://api.lyra.com/api-payment/V4/Charge/CreateToken',options,  { headers: headers })

  }

  setChosenRestaurant(itemIndex, restaurant) {
    let cardParse = JSON.parse(localStorage.getItem('cart')) || []
    if (cardParse[itemIndex]) {
      cardParse[itemIndex].restaurant = this.getDataFromRestaurant(restaurant)
      localStorage.setItem('cart', JSON.stringify(cardParse));
      this.cartSubject.next(cardParse);
    }
  }

  setChosenSpectacle(itemIndex, spectacle, placesListContent) {
    let cardParse = JSON.parse(localStorage.getItem('cart')) || []
    if (cardParse[itemIndex]) {
      cardParse[itemIndex].spectacle = this.getDataFromSpectacle(spectacle)
      cardParse[itemIndex].spectacle.placesListContent = this.getDataFromPlacesListContent(placesListContent)
      localStorage.setItem('cart', JSON.stringify(cardParse));
      this.cartSubject.next(cardParse);
    }
  }



  removeFromCard(item, index, type) {
    let cardParse = JSON.parse(localStorage.getItem('cart')) || []

    let filtredCart = []

    switch (type) {
      case "spectacle": {
        cardParse[index].spectacle = null
        break;
      }
      case "restaurant": {
        cardParse[index].restaurant = null
        if (cardParse[index].spectacle && !cardParse[index].spectacle.sp_id) cardParse[index].spectacle = null
        break;
      }

      case "type": {
        cardParse[index] = null
        break;
      }
    }

    filtredCart = cardParse
    filtredCart = _.filter(filtredCart, (item) => {

      if (!item) {
        return false
      }
      else if (!item.type && (type == "spectacle" || type == "restaurant")) {
        return (!(!item.spectacle && !item.restaurant))
      } else {
        return (item)
      }
    });
    localStorage.setItem('cart', JSON.stringify(filtredCart));
    this.cartSubject.next(filtredCart);
  }

  getItemCard() {
    let cardParse = JSON.parse(localStorage.getItem('cart')) || []
    return cardParse
  }

  getItemFromCart(index) {
    let cardParse = JSON.parse(localStorage.getItem('cart')) || []
    return cardParse[index]
  }

  clearCart() {
    localStorage.setItem('cart', JSON.stringify([]));
    this.cartSubject.next([]);
  }

  removeByIdex(index, type) {
    let cardParse = JSON.parse(localStorage.getItem('cart')) || []
    let cardsGiftsParse = _.filter(JSON.parse(localStorage.getItem('cart')), (item) => item.type) || []
    let privileges = _.filter(JSON.parse(localStorage.getItem('cart')), (item) => !item.type) || []
    if (type == 'gift') {
      let itemToRemove = cardsGiftsParse[index]
      cardsGiftsParse = _.filter(cardsGiftsParse, (item) => item != itemToRemove);
    } else {
      let itemToRemove = privileges[index]
      privileges = _.filter(privileges, (item) => item != itemToRemove);
    }
    cardParse = Array.from(new Set(cardsGiftsParse.concat(privileges)))

    localStorage.setItem('cart', JSON.stringify(cardParse));
    this.cartSubject.next(cardParse);
  }


  saveLivraisonAddress(data) {
    localStorage.setItem('livraisonData', JSON.stringify(data));
  }

  saveLivraisonType(data) {
    localStorage.setItem('livraisonType', data);
  }

  getLivraisonType() {
    return localStorage.getItem('livraisonType')
  }

  getLivraisonAddress() {
    let livraisonData = JSON.parse(localStorage.getItem('livraisonData')) || null
    return livraisonData
  }

  giftCardsPrice(item, qty) {
    return item * qty
  }

  getSignature(item, key) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'));

    return this.http.post(`${environment.clientUrl}/get_signature/${key}`, item, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  accessToPay(data, signature) {
    const formdata: FormData = new FormData();
    formdata.append('signature', signature);
    formdata.append('vads_action_mode', data.vads_action_mode);
    formdata.append('vads_amount', data.vads_amount);
    formdata.append('vads_ctx_mode', data.vads_ctx_mode);
    formdata.append('vads_currency', data.vads_currency);
    formdata.append('vads_page_action', data.vads_page_action);
    formdata.append('vads_payment_config', data.vads_payment_config);
    formdata.append('vads_site_id', data.vads_site_id);
    formdata.append('vads_trans_date', data.vads_trans_date);
    formdata.append('vads_trans_id', data.vads_trans_id);
    formdata.append('vads_version', data.vads_version);
    return this.http.post('https://sogecommerce.societegenerale.eu/vads-payment/', formdata)
  }

  getDataFromRestaurant(restaurant) {
    if (!restaurant) return null
    restaurant = _.pick(restaurant, ['id', 'court_desc', 'image', 'latitude', 'longitude', 'margin_price', 'name', 'street', 'time', 'zenchef_id', 'selectedPlacesRestaurant', 'min_price', 'openingschedule', 'min_formule']);
    return restaurant
  }

  getDataFromSpectacle(spectacle) {
    let filterSpectacle = _.pick(spectacle, ['sp_id', 'sp_nom', 'sp_visuel', 'th_adresse', 'th_lat', 'th_long', 'th_nom', 'th_ville', 'total', 'sp_desc_court', 'selectedDate', 'selectedTime', 'scat_texte', 'cat_texte']);
    return filterSpectacle
  }

  getDataFromPlacesListContent(placesListContent) {
    placesListContent = placesListContent.map(item => _.pick(item, ['tr_tarif', 'tr_reduit', 'tr_sp_id', 'tr_seance', 'tr_quotas', 'tr_qte', 'tr_ordre', 'tr_intitule', 'tr_frais', 'tr_entite', 'tr_date']))
    return placesListContent
  }

  setChecksToUse(checksToUse) {
    this.checksToUse = checksToUse;
  }

  getChecksToUse() {
    return this.checksToUse;
  }

  getTotalUsedChecks( totalPrice ) {
    var sum = 0;
    this.checksToUse.forEach(item => {
      if ( totalPrice >= sum ){
        sum = item.old_value * 1 + sum;
      }
    })
    return sum ;
  }

  getChecksUsedAmount() {
    let total_used_amount = _.reduce(this.checksToUse, function(sum, item) {
      return sum + ( item.old_value - item.new_value );
    }, 0);
    return total_used_amount ;
  }


}
