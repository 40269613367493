import { Injectable } from '@angular/core';
import { Gift} from 'src/app/models/GiftCard';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GiftService {

  gifts: Gift[];
  constructor(private http: HttpClient) { }

  public AddGiftCard(values: any):Promise<any> {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.post(`${environment.adminUrl}/check`, values, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) }).toPromise()
  }


  public getAllGifts(filter, sortCulumn, sortDirection, pageIndex, pageSize, status ) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.get(`${environment.adminUrl}/checks/${sortCulumn}/${sortDirection}/${pageIndex}/${pageSize}/${status}?filter=${filter}`, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) });
  }



  public updateGift(gift: any) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.put(`${environment.adminUrl}/check/update/` + gift.id, gift, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })

  }
  public deleteGift(id: number) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.delete(`${environment.adminUrl}/check/${id}`, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) }).subscribe(
      result => console.log(result),
      err => console.error(err));
  }

  getAllGiftsUser( status ) {
   let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
   return this.http.get(`${environment.clientUrl}/checks/all/${status}`, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) });
  }


 public generateUniqSerial() {
   let ts = String(new Date().getTime());
   let genId = '';
   let randomStr = '';

   for (let i = 0; i < ts.length; i += 2) {
       genId += Number(ts.substr(i, 2)).toString(36);
   }

   randomStr ='xxxxxx'.replace(/[x]/g, function (c) {
     var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
     return v.toString(16)
   });
   randomStr = randomStr.slice(0,16-genId.length)
   return (randomStr + genId);
 }

 extendCheque(id,dateValidation) {
  let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
  return this.http.put(`${environment.adminUrl}/check/extend/`+id, {dateValidation:dateValidation},{ headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
}


sendGift(data,id){
  let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
  return this.http.post(`${environment.clientUrl}/check/send/`+id, data, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })

}

activeGift(id){
  let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
  return this.http.get(`${environment.adminUrl}/check/active/`+id, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
}

changeStatus( id ){
  let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
  return this.http.put(`${environment.clientUrl}/check/changeStatus/`+id, {}, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
}


addToAccount( id ){
  let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
  return this.http.put(`${environment.clientUrl}/check/addToAccount/`+id, {}, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
}


unActiveGift(id){
  let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
  return this.http.get(`${environment.adminUrl}/check/unactive/`+id, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
}

  formatChequeSerial(uniqueSerialId){
    let newval = ''
    for (let i = 0; i < uniqueSerialId.length; i ++) {
      if(i%4 == 0 && i > 0 && i <16) newval = newval.concat('-');
      newval = newval.concat(uniqueSerialId[i]);
    }
    return newval
  }

}
