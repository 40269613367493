import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CartService } from '@_shared/services/cart.service'
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment'
import * as _ from 'lodash';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['../header.component.sass']
})

export class CartComponent implements OnInit {
  public serverUrl
  public cartItems = []
  public total = 0
  public giftCardsTotal = 0
  public disableBookBtn = false;
  public giftsItems = []
  constructor(private router: Router, private cartService: CartService) { }

  ngOnInit() {
    this.serverUrl = environment.serverUrl
    this.cartService.myCart$.subscribe(res => {
      this.total = 0
      this.giftCardsTotal = 0
      this.cartItems = this.cartService.getItemCard() || res
      this.disableBookBtn = false
      if (this.cartItems.length == 0) this.disableBookBtn = true




      // let itemPrice = 0
      // if ( item.placesListContent ){
      //   item.placesListContent.forEach( value => {
      //     itemPrice += ( value.tr_reduit || value.tr_tarif)*value.tr_qte
      //   });
      //
      //   let price_restaurant = 0;
      //   if(item.restaurant && item.restaurant.price) price_restaurant = item.restaurant.price*1
      //   itemPrice +=  price_restaurant
      //
      // }else{
      //   itemPrice = 0
      // }
      // if()
      this.cartItems.forEach(item => {
        if (item.type) {
          this.giftCardsTotal += item.totalPrice
        } else {
          if (item.spectacle && item.restaurant) {
            if ( item.spectacle.placesListContent && item.spectacle.placesListContent.length > 0 && item.restaurant.selectedPlacesRestaurant && item.restaurant.selectedPlacesRestaurant.length > 0) {
            // if (item.spectacle.placesListContent.length > 0 && item.restaurant.selectedPlacesRestaurant.length > 0) {

              item.spectacle.placesListContent.forEach(place => {
                this.total += place.tr_qte * ((place.tr_reduit ? place.tr_reduit : place.tr_tarif) * 1 + place.tr_frais * 1);
              })

              item.restaurant.selectedPlacesRestaurant.forEach(place => {
                this.total += place.qte * (place.price * 1 + item.restaurant.margin_price * 1);
              })

            } else {
              this.disableBookBtn = true
            }
          } else {
            this.disableBookBtn = true
          }


        }
      });

      this.total += this.giftCardsTotal

    })
    setTimeout(() => this.cartService.initCart(), 100)
  }

  removeFromCart(item, index, type) {
    this.cartService.removeFromCard(item.id, index, type)
  }

  goToRestaurants(index, itemsToShow) {
    this.closeSideNav()
    this.router.navigate(['/restaurants', index + "", 'search']);

  }

  goToShows(index, itemsToShow) {
    this.closeSideNav()
    this.router.navigate(['/shows', index + "", 'search']);

  }

  goToDetails(index) {
    this.closeSideNav()
    this.router.navigate(['/restaurant', 'add', index + ""], { queryParams: { cart: 'show' } });
  }

  goToCommand() {
    this.closeSideNav()
    this.router.navigate(['/Command']);

  }

  closeSideNav() {
    this.cartService.opencloseCart(false)
  }

}
