import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http'
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { User } from 'src/app/models/user';
import { config } from '../../config';
import { map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private token: string;
  user: any;
  myToken$: Observable<string>;
  tokenSubject: Subject<string> = new Subject<string>();
  public switchBack = new BehaviorSubject<string>(localStorage.getItem("switchBack") || "");


  constructor(private http: HttpClient, private route: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.myToken$ = this.tokenSubject.asObservable();

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  get canSwitchback() {
    return this.switchBack.asObservable(); // {2}
  }

  login(username: string, password: string) {
    return this.http.post<any>((`${environment.baseUrl}/auth/login`), { username, password })
      .pipe(map(user => {
        if (user && user.token) {
          this.saveToken(user.token);
        }
        return user.token;

      }));
  }

  saveToken(token) {
    localStorage.setItem('currentUser', JSON.stringify(token));
    var decoded_token = jwt_decode(token);
    let actual_tsp = new Date().getTime() / 1000
    let timeToLogout = decoded_token.exp - actual_tsp
    if (timeToLogout < 0) this.logout()
    else setTimeout(() => this.logout(), 1000 * timeToLogout)
    this.http.get(`${environment.clientUrl}/user`, { headers: new HttpHeaders().append('Authorization', `Bearer ${token}`) })
      .subscribe((result: any) => {
        localStorage.setItem('nameUser', JSON.stringify(result.firstname));
        this.tokenSubject.next(token);
      })
    this.tokenSubject.next(token);
    setTimeout(() => this.logout(), 864000000)
  }

  logout() {

    localStorage.removeItem('currentUser')
    localStorage.removeItem('livraisonData')
    localStorage.removeItem('livraisonType')
    localStorage.removeItem('nameUser')
    localStorage.removeItem('cart')
    localStorage.removeItem('last_command_id')
    localStorage.removeItem('switchBack')
    // this.currentUserSubject.next(null);
    this.tokenSubject.next(null);
    // this.route.navigateByUrl('/')
  }


  getUserdetails() {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.adminUrl}/user`, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return user !== null;
  }

}
