import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { config } from '../../config';
import { AuthService } from '../../_shared/services/auth.service';
import { environment } from "src/environments/environment";
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url

    const currentUserToken = JSON.parse(localStorage.getItem('currentUser'));
     if (currentUserToken) {
       request = request.clone({
         // setHeaders: {
         //   'Content-Type': 'application/json',
         //   Authorization: `Bearer ${currentUserToken}`
         // }
       });
    }
    return next.handle(request).pipe( tap(() => {},
      (err: any) => {
      if (err == 'Expired JWT Token' || err == 'Invalid JWT Token') {
        this.authService.logout()
      }
    }));

  }
}
