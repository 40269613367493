import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SearchFilterFormComponent } from './components/search-filter-form/search-filter-form.component';

import { HeaderComponent } from './components/header/header.component';
import { CartComponent } from './components/header/cart/cart.component';
import { FooterComponent } from './components/footer/footer.component';
import { DetailsGiftDialogComponent } from './components/details-gift-dialog/details-gift-dialog.component';

import { SpinnerComponent } from './components/spinner/spinner.component';
// import { ChangePwdComponent } from './components/settings/changepwd/changepwd.component';
// import { UserComponent } from './components/settings/user/user.component';
// import { UsersListComponent } from './components/settings/usersList/users-list/users-list.component';
// import { SidenavComponent } from './components/sidenav/sidenav.component';
// import { SpinnerComponent } from './components/spinner/spinner.component';

import { MaterialModule } from './material/material.module';

@NgModule({
  declarations: [
    /* Components */
    HeaderComponent,
    FooterComponent,
    CartComponent,
    DetailsGiftDialogComponent,
    SpinnerComponent,
    SearchFilterFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MaterialModule,
  ],
  exports: [
    MaterialModule,
    CommonModule,
    HeaderComponent,
    FooterComponent,
    CartComponent,
    DetailsGiftDialogComponent,
    SpinnerComponent,
    SearchFilterFormComponent
    // SpinnerComponent,
    // UserComponent,
    // ChangePwdComponent,
    // UsersListComponent,
    // TranslateModule
  ]
})
export class SharedModule { }
