import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from './loader/loader.component';
import { LocalLoaderComponent } from './local-loader/local-loader.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    LocalLoaderComponent,
    LoaderComponent
  ],
  exports: [
    LocalLoaderComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
  ],

})
export class LoadersModule { }
