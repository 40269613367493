import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { config } from '../../config';
import { User } from '../../models/user';

import { environment } from "src/environments/environment";
import { Address } from 'src/app/models/Address';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';



@Injectable({ providedIn: 'root' })
export class UserService {
  users: User[];

  constructor(private http: HttpClient, private toastr: ToastrService) {

    this.myUser$ = this.userSubject.asObservable();

  }
  user: User;
  address: Address;
  myUser$: Observable<string>;
  userSubject: Subject<string> = new Subject<string>();



  getUserList(filter, sortCulumn, sortDirection, pageIndex, pageSize, status) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    // let myHeaders = new Headers();
    return this.http.get(`${environment.adminUrl}/users/${sortCulumn}/${sortDirection}/${pageIndex}/${pageSize}/${status}?filter=${filter}`, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) });
  }

  getUserdetails() {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.clientUrl}/user`, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  public deleteUser(id: number) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.delete(`${environment.adminUrl}/user/` + id, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  public activeUser(id: number) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.get(`${environment.adminUrl}/user/activate/` + id, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  public updateUser(civility, firstname, lastname, tel, street, city, country, postalcode, email, id) {


    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))

    this.user = new User();
    this.address = new Address()

    this.user.username = email;
    this.user.civility = civility;
    this.user.firstname = firstname;
    this.user.lastname = lastname;
    this.user.tel = tel;
    this.user.email = email;
    this.address.street = street;
    this.address.city = city;
    this.address.country = country;
    this.address.postal_code = postalcode;

    this.user.address = this.address;
    return this.http.put(`${environment.clientUrl}/user/update/` + id, this.user, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  updateUserPassword(values) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.put(`${environment.clientUrl}/user/password/update`, values, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })

  }
  getUserById(id) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.get(`${environment.adminUrl}/user/` + id, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  resetPassword(email) {
    return this.http.get(`${environment.publicUrl}/reset/password/${email}`)
  }

  updateUserForAdmin(values, id) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    let user = new User();
    let address = new Address()
    user.username = values.email;
    user.civility = values.civility;
    user.firstname = values.firstname;
    user.lastname = values.lastname;
    user.tel = values.tel;
    user.email = values.email;

    address.street = values.street;
    address.city = values.city;
    address.country = values.country;
    address.postal_code = values.postalcode.toString();
    user.address = address;
    if (values.password && values.password.length > 2) {
      user.password = values.password;
    } else {
      user.password = null
    }
    return this.http.put(`${environment.adminUrl}/user/update/` + id, user, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }
  switchToUser(email){
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.get(`${environment.adminUrl}/user/switch/action?_switch_user=` + email, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

}
