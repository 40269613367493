import { Component } from '@angular/core';
import { User } from './models/user';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { AuthService } from './_shared/services/auth.service'
import { SpectacleService } from './_shared/services/spectacle.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'web';

  showHead: boolean = false;
  currentUser: User;

  constructor(private router: Router, private authService: AuthService, public spectacleService: SpectacleService) {
    // on route change to '/login', set the variable showHead to false
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'].indexOf('/admin') !== -1) {
          this.showHead = false;
        } else {
          // console.log("NU")
          this.showHead = true;
        }
      }
    });
    // this.authService.currentUser.subscribe(x => this.currentUser = x)
  }

  ngOnInit() {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  logout() {
    this.authService.logout()
    this.router.navigate(['/auth'])
  }
}
