import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';
import * as jwt_decode from 'jwt-decode';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'));
    if (tokenParse) {
      var decoded_token = jwt_decode(tokenParse);
      var index = decoded_token.roles.findIndex(x => x == "ROLE_USER")
      if (index != -1) { return true; }

      this.router.navigate(['/authentication'], { queryParams: { returnUrl: state.url }});
      return false;
    }
    else {

      this.router.navigate(['/authentication'], { queryParams: { returnUrl: state.url }});
      return false;
    }

  }
}
