import { Address } from './Address';

export class User {
  id: Number
  username: string;
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  tel: string;
  civility: string
  address: Address
  token?: string;
}