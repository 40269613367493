import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-details-gift-dialog',
  templateUrl: './details-gift-dialog.component.html',
  styleUrls: ['./details-gift-dialog.component.sass']
})
export class DetailsGiftDialogComponent implements OnInit {
  customizedMessage
  constructor(private dialogRef: MatDialogRef<DetailsGiftDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any)  {
  }

  ngOnInit() {
  }


}
