// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
* backend urls :
*  - /admin : need authentication with role admine to get access
*  - /client : need athentication with role user to get access
*  - /public : no authentication nedded
*/
export const environment = {
  production: true,
  assetbaseUrl: 'https://soireesprivilege.com/assets/img',
  clientbaseUrl: 'https://soireesprivilege.com/#',
  serverUrl: 'https://soireesprivilege.com/api',
  baseUrl:   'https://soireesprivilege.com/api/api',
  clientUrl: 'https://soireesprivilege.com/api/api/client',
  adminUrl:  'https://soireesprivilege.com/api/api/admin',
  publicUrl: 'https://soireesprivilege.com/api/api/public',
  vosFacturesHost: 'arobaseproduction.vosfactures.fr',
  vosFacturesToken: '02lOWvAMKNtmR8yV6',
  boutique_id: '91423244',
  test_key: 'tVrL4HZ5yDhLtTtD',
  prod_key: '3doW41tGwVOAq9Iy',
  order_info: 'soireesprivilege'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
