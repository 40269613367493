import { Component, OnInit, ElementRef, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import {Router} from '@angular/router';
import { SpectacleService } from '@_shared/services/spectacle.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-filter-form',
  templateUrl: './search-filter-form.component.html',
  styleUrls: ['./search-filter-form.component.sass'],
  providers: [DatePipe]
})
export class SearchFilterFormComponent implements OnInit {
  $getSCategoriesListSubscription :Subscription;
  public minCurrentDate = new Date();
  myForm: FormGroup;
  constructor(private datePipe: DatePipe, private fb: FormBuilder, private router: Router,public spectaclesService: SpectacleService) {
    this.initForm()
  }
  public s_categorylist = [
    { label: null }
 ];

  initForm() {
    this.myForm = this.fb.group({
      // search_key: ['', [Validators.required, Validators.minLength(3)]],
      search_key: [null, []],
      search_pers: [null, []],
      search_date: [null, []],
      search_s_category: [null, []],
    })
  }

  ngOnInit() {
    this.$getSCategoriesListSubscription = this.spectaclesService.getSCategoriesList().subscribe((res:any[])=>{

      res.forEach(element => this.s_categorylist.push({ label: element.scat_texte }));
    })
  }
  onSubmit() {
    let search_date = null
    let search_date_tsp = null
    let search_s_category = null
    if ( this.myForm.value.search_s_category =='null' ) this.myForm.value.search_s_category = null
    if ( this.myForm.value.search_date ) search_date = new Date(this.myForm.value.search_date)
    if ( search_date ) search_date_tsp = ( ( search_date.getTime() )/1000 )-3600

    this.spectaclesService.setMySearchParams({"search_s_category":this.myForm.value.search_s_category,"search_key":this.myForm.value.search_key,"search_pers":this.myForm.value.search_pers,"search_date_tsp":search_date_tsp})
    this.router.navigate(['/shows/search'],
    { queryParams:
      { "search_s_category": this.myForm.value.search_s_category,
       "search_key":this.myForm.value.search_key,
       "search_pers":this.myForm.value.search_pers,
       "search_date_tsp":search_date_tsp }
     }
   );

  }
  ngOnDestroy() {
    if (this.$getSCategoriesListSubscription) this.$getSCategoriesListSubscription.unsubscribe();
  }
}
