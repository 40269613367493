import { Injectable } from '@angular/core';
import { Address } from 'src/app/models/Address';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpectacleService {
  public min_sp = 0;
  public min_restaurant = 0;
  public address: Address[];
  public selectedSpectacle: any;
  public mySearchParams$: Observable<string>;
  public searchParamsSubject: Subject<string> = new Subject<string>();

  constructor(private http: HttpClient) {
    this.mySearchParams$ = this.searchParamsSubject.asObservable();
  }

  getbestShowslist() {
    return this.http.get(`${environment.publicUrl}/best_shows`)
  }

  getMinPrices() {
    let last_command_id = localStorage.getItem('last_command_id') ? localStorage.getItem('last_command_id') : null
    return this.http.get(`${environment.publicUrl}/min_prices?last_command_id=${last_command_id}`)
  }

  selectSpectacle(spectacle) {
    this.selectedSpectacle = spectacle;
  }

  getShowsDetails(id) {
    return this.http.get(`${environment.publicUrl}/show_details/${id}`)
  }

  getShows(displayOptions) {
    return this.http.post(`${environment.publicUrl}/shows`, displayOptions)
  }

  getShowsDisponibilies(sp_id, sp_df) {
    return this.http.post(`${environment.publicUrl}/shows_disponibilities`, { "sp_id": sp_id, "sp_df": sp_df })
  }

  enDatestShowslist() {
    return this.http.get(`${environment.publicUrl}/en_date_shows`)
  }

  showsYouLikedList() {
    return this.http.get(`${environment.publicUrl}/shows_you_may_liked`)
  }

  setMySearchParams(mySearchParams) {
    this.searchParamsSubject.next(mySearchParams);
  }

  getSuggestionList(lng, lat) {
    return this.http.get(`${environment.publicUrl}/suggestion_restaurants/${lng}/${lat}`)
  }

  getSCategoriesList( ) {
    return this.http.get(`${environment.publicUrl}/s_categories`)
  }

  getCategoriesList( ) {
    return this.http.get(`${environment.publicUrl}/categories`)
  }

  getSearchData( ) {
    return this.http.get(`${environment.publicUrl}/searchData`)
  }


  getAllShows() {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.get(`${environment.adminUrl}/all_shows`, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  getShowsActivated() {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.get(`${environment.adminUrl}/shows`, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  getAllShowsForRecommendation() {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.get(`${environment.adminUrl}/all_shows/recommendation`, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }
  publishShow(id: number) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.post(`${environment.adminUrl}/addshow/` + id, {}, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  unPublishShow(id: number) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.delete(`${environment.adminUrl}/show/` + id, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  publishAll(spectaclesToPublish) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.post(`${environment.adminUrl}/publishAll`, { spectaclesToPublish: spectaclesToPublish }, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  DeleteAll(spectaclesToDelete) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.post(`${environment.adminUrl}/deleteAll`, { spectaclesToDelete: spectaclesToDelete }, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  lastCommentslist() {
    return this.http.get(`${environment.publicUrl}/lastCommentShows`)
  }

  setMinPrices(minPrices) {
    if (minPrices.sp_min_reduit == 0 && minPrices.sp_min_plein > 0) {
      this.min_sp = minPrices.sp_min_plein * 1
    } else if (minPrices.sp_min_reduit > 0 && minPrices.sp_min_plein == 0) {
      this.min_sp = minPrices.sp_min_reduit * 1
    } else if (minPrices.sp_min_reduit > 0 && minPrices.sp_min_plein > 0) {
      this.min_sp = Math.min(minPrices.sp_min_plein, minPrices.sp_min_reduit) * 1
    }
    this.min_restaurant = minPrices.min_restaurant * 1
  }
}
