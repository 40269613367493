import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { } from '../search-filter-form/search-filter-form.component'
import { AuthService } from 'src/app/_shared/services/auth.service';
import { CartService } from 'src/app/_shared/services/cart.service';

import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/models/user';
import { UserService } from 'src/app/_shared/services/user.service';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})



export class HeaderComponent implements OnInit {
  user: User
  public isLogedIn;
  public screenWidth: number;
  public openBackdrop: boolean;
  public name: string
  canSwitch$: Observable<string>;

  constructor(private authservice: AuthService, private http: HttpClient, private router: Router, private cRef: ChangeDetectorRef, private userservice: UserService, private cartService: CartService) {
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.canSwitch$ = this.authservice.canSwitchback;

    let token = JSON.parse(localStorage.getItem('currentUser'));
    if (token) {
      setTimeout(() => this.authservice.saveToken(token), 100)
    }
    this.authservice.myToken$.subscribe(token => {
      if (token) {
        this.isLogedIn = true
        this.name = JSON.parse(localStorage.getItem('nameUser'));
      }
      else {
        this.router.navigate(['/authentication']);
        this.isLogedIn = false
      }
    })

    this.userservice.myUser$.subscribe(res => {
      this.name = JSON.parse(localStorage.getItem('nameUser'));
    })

    this.cartService.myCartStatus$.subscribe((open:boolean) => {
      this.openBackdrop = open
      if ( open ) document.getElementById("mySidenavv").style.width = this.screenWidth > 760 ? '500px' : '100%';
      else  document.getElementById("mySidenavv").style.width = "0";
    })

  }

  openNav() {
    document.getElementById("mySidenav").style.width = "100%";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }


  openCart() {
    this.cartService.opencloseCart(true)
  }

  closeCart() {
    this.cartService.opencloseCart(false)
  }

  logout() {
    
    setTimeout(() => {
      window.location.reload()
    }, 200);

    this.authservice.logout()

  }

  isLoggedIn() {
    return this.authservice.isLoggedIn
  }

  isAdmin() {
    var decoded_token = jwt_decode(JSON.parse(localStorage.getItem('currentUser')));
    var index = decoded_token.roles.findIndex(x => x == "ROLE_SUPER_ADMIN")
    if (index != -1) {
      return true;
    }
  }
  switchBackToAdmin(){
    localStorage.setItem('currentUser', localStorage.getItem('switchBack'));
    localStorage.removeItem('switchBack');
    this.authservice.switchBack.next("");
    
    setTimeout(() => {
      window.location.reload()
    }, 200);
    this.router.navigate(["/admin/gestionusers"])

  }



}
