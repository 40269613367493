import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import * as jwt_decode from 'jwt-decode';
import { Gift } from "../../models/Gift";
import { DatePipe } from '@angular/common';
import { GiftService } from './gift.service';

@Injectable({
  providedIn: 'root'
})
export class CommandService {



  constructor(private giftService: GiftService, private datePipe: DatePipe, private http: HttpClient) {

  }

  getUserCommands(days) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.clientUrl}/Commands/${days}`, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })

  }

  getUseContreMarque() {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.clientUrl}/Contremarques`, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })

  }



  saveCommand( options, isAdmin) {
    let giftsCardsObjects = []
    let user_id = null;
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'));
    let cardsList = options.commandLines.filter( item => item.type && item.amount)
    if (cardsList.length !=0){
      giftsCardsObjects = this.CreateGiftCard(cardsList)

    }
    options.id = this.makeid()
    options.adresse = localStorage.getItem('livraisonData')
    options.commandLines = options.commandLines.filter( item => !item.type && !item.amount).map(item => JSON.stringify(item))
    if ( isAdmin ) {
      options.userId = JSON.parse(localStorage.getItem('livraisonData')).id
    }
    if (cardsList.length !=0 ){
    options.giftsCardsObjects = giftsCardsObjects
    }
    return this.http.post(`${environment.clientUrl}/Command`, options, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  CreateGiftCard(cardsList){
    let giftObjects = []
    let sharedMessage = cardsList[0]["message"]
    let sharedThematic = cardsList[0]["thematic"]
    cardsList.forEach(element => {
      for (let i = 0; i < element.qte; i++) {
        let giftObject = {} as Gift
        giftObject.giftTypeId = element.id
        giftObject.type = element.type
        giftObject.purshasedDate = new Date()
        giftObject.activationDate = null
        giftObject.initialBalance = element.amount
        giftObject.remainingBalance = element.amount
        giftObject.personalizedMessage = sharedMessage || ''
        giftObject.thematic = sharedThematic
        giftObject.status = false
        giftObject.validationDate = null
        giftObject.uniqueSerialId = this.giftService.generateUniqSerial()
        giftObjects.push(giftObject)
      }
    });
    return giftObjects
  }

  makeid() {
    let ts = String(new Date().getTime());
    let out = '';

    for (let i = 0; i < ts.length; i += 2) {
      out += Number(ts.substr(i, 2)).toString(36);
    }

   return out.slice(0,11);
  }

  getCommands( filter, sortCulumn, sortDirection, pageIndex, pageSize, status, deliveryMode ) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.adminUrl}/Commands/${sortCulumn}/${sortDirection}/${pageIndex}/${pageSize}/${status}/${deliveryMode}?filter=${filter}`, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  getCommandDetails(id) {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get(`${environment.adminUrl}/CommandDetails/` + id, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  deleteCommand(id){
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.delete(`${environment.adminUrl}/command/` + id, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  updateLC(id , content){
    let lc = JSON.stringify(content)
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.put(`${environment.adminUrl}/command/updateLC/` + id, {content: lc},  { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })

  }

  updateStatusCommand(status,id){
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.post(`${environment.adminUrl}/command/status/` + id,{ 'vads_order_id': id, 'vads_trans_status':status }, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  updateDeliveryModeCommand(mode,id){
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.put(`${environment.adminUrl}/command/deliveryMode/` + id,{mode:mode}, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  updateCommandAdress( command ){
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    let address = JSON.stringify( command.addresse )
    return this.http.put(`${environment.adminUrl}/command/updateAddress/` + command.id,{ address:address }, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  updateCommandComment( comment, id ){
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.put(`${environment.adminUrl}/command/updateComment/` + id,{ comment:comment }, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

  getFirstCommand() {
    let tokenParse = JSON.parse(localStorage.getItem('currentUser'))
    return this.http.get(`${environment.clientUrl}/command/FirstCommand`, { headers: new HttpHeaders().append('Authorization', `Bearer ${tokenParse}`) })
  }

}
